



































import { Component, mixins } from 'nuxt-property-decorator'
import BasePage from '@Marketing/mixins/BasePage.vue'
import PageContainer from '@Marketing/components/containers/PageContainer.vue'
import FlexibleLayoutContainer from '@Marketing/components/containers/FlexibleLayoutContainer.vue'

@Component({
  components: {
    PageContainer,
    FlexibleLayoutContainer,
  },
  layout: 'home',
})
export default class Home extends mixins(BasePage) {
  get mainComponentOverrides() {
    return {
      FeaturedPostCollection: 'FeaturedPostsHero',
      FeaturedEventCollection: 'FeaturedGigCollection',
      Tile: 'AboutUs',
    }
  }

  get isHomepage() {
    if (this.page && this.page.slug) {
      return this.page.slug === 'homepage'
    }
    return false
  }
}
